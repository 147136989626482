<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8">
          <h4 class="header">Reports</h4>
        </div>
      </div>

      <div class="row">
        <b-col >
          <b-form-group
          description="Filter by Room ID"
          label-for="room-filter"
          sm="sm"
          >

            <b-form-select
            id="room-filter"
            v-model="selectedRoom"
            :options="allLivestreamRooms"/>
          </b-form-group>
        </b-col>

        <b-col  >
          <b-form-group
            description="Filter reported users"
            label-for="filter-input">

            <b-input-group>
              <b-form-input
                id="filter-input"
                autocomplete="off"
                v-model="reportedUserFilter"
                placeholder="Enter user id or username"
                type="search"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            description="Search users globally"
            label-for="filter-input">
            <b-input-group>
              <b-form-input v-model="globalSearchQuery" placeholder="Enter user id or username" type="search" autocomplete="off" @keyup.enter="getMatchingUsers(globalSearchQuery)"/>
              <b-input-group-append>
                <b-button @click="getMatchingUsers(globalSearchQuery)" class="btn-global-find">Find</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- <div class="user-search">
        </div> -->
      </div>

      <br>

      <b-row align-h="center">
        <div class="col-md-12">
            <b-table
            @scroll.native="handleScroll"
            sticky-header="70vh"
            :busy="busy" striped
            :items="reports"
            :filter-function="filterRoom"
            :fields="fields" ref="ifList">
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <!-- Virtual composite columns -->
              <template #cell(by_user)="data">
                <p> <strong>ID:</strong> {{ data.item.by_user}}</p>
                <p v-if="data.item.by_username"> <strong>Username:</strong> {{data.item.by_username }}</p>
              </template>

              <template #cell(reported_user_id)="data">
                <p> <strong>ID:</strong> {{ data.item.reported_user_id}}</p>
                <p v-if="data.item.reported_user"> <strong>Username:</strong> {{data.item.reported_user }}</p>
              </template>

              <template #cell(messages)="data">
                <div v-if="Array.isArray(data.item.messages)">
                  <!-- {{data.item._id}} -->
                  <b-button @click="showMessagesModal(data.item)" class="btn-messages btn-block">Show Messages</b-button>
                    <b-collapse :id="data.item._id">
                      <ul v-for="(message, index) in data.item.messages" :key="index">
                        <li> {{message.user}}: {{message.message}} </li>
                      </ul>
                    </b-collapse>
                </div>
                <p v-else>Missing messages data</p>
              </template>
            </b-table>
        </div>

        <div class="col-md-12 justify-content-center d-flex">
          <b-button v-if="showLoadMore" @click="changePage" class="load-more">Load More</b-button>
        </div>
      </b-row>
        <!-- Find Users Modal-->
      <b-modal id="findUser" ref="findUser" hide-footer no-close-on-backdrop no-close-on-esc>
        <h4>Results for: <span>{{ globalSearchQuery }}</span></h4>
        <div class="user-found-wrapper" v-if="searchUsers.length">
          <div class="user-found" v-for="(u, index) in searchUsers" :key="u._id">
            <div class="username">{{ u.chat.username }}</div>
            <div class="email">{{ u.email }}</div>
            <div class="id">{{ u._id }}</div>
            <div v-if="u.blocked.length>0"> <strong> Blocked In: </strong> {{ u.blocked.join() }}</div>
            <b-row>
              <b-col cols="12" class="d-flex">
                <b-button :disabled="u.blocked.includes('all')"
                v-if="!u.confirmation"
                variant="dark"
                class="btn-block"
                @click="platformBlock(u.chat.username, u._id, index)"
                >Block</b-button>

                <b-button :disabled="!u.blocked.includes('all')"
                v-if="!u.confirmation"
                variant="dark"
                class="btn-block"
                @click="platformUnblock(u.chat.username, u._id, index)"
                >Unblock</b-button>
              </b-col>

              <b-col cols="12">
                <div class="confirmation-wrapper" v-if="!( u.blocked.includes('all') ) && u.confirmation">
                  <p class="info-text">This will block the user from chatting across all apps</p>
                  <div class="btns-wrapper">
                    <div class="btn-block" @click="cancelPlatformBlock(index)">Cancel</div>
                    <div class="btn-block" @click="confirmPlatformBlock(index)">Confirm</div>
                  </div>
                </div>

                <div class="confirmation-wrapper" v-if="u.blocked.includes('all') && u.confirmation">
                  <p class="info-text">This will unblock the user from all rooms</p>
                  <div class="btns-wrapper">
                    <div class="btn-block" @click="cancelPlatformUnblock(index)">Cancel</div>
                    <div class="btn-block" @click="confirmPlatformUnblock(index)">Confirm</div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-modal>

        <!-- Show message Modal-->
      <b-modal id="showMessages" ref="showMessages" hide-footer no-close-on-backdrop no-close-on-esc>
        <h4>Messages History</h4>
        <div class="user-found-wrapper messages-history-list">
          <ul style="list-style-type:none;">
            <li v-for="(message, index) in modalMessages" :key="index">
              <span class="sm-date">{{moment(message.timestamp).format('l, h:mm:ss a')}}</span> <br>
              {{message.user}}: {{message.message}}
            </li>
          </ul>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { debounce } from './../helpers.js'
export default {
  name: 'Reports',
  data () {
    return {
      reports: [],
      allLivestreamRooms: [],
      userToblock: {},
      userToUnblock: {},
      selectedRoom: '',
      currentPage: 1,
      globalSearchQuery: '',
      searchUsers: [],
      queryObject: {},
      modalMessages: [],
      showLoadMore: false,
      reportedUserFilter: '',
      busy: false,
      fields: [
        { key: 'room', sortable: false },
        { key: 'by_user', label: 'Reported By', sortable: false },
        { key: 'reported_user_id', label: 'Reported User', sortable: false },
        { key: 'reported_date', sortable: true, formatter: 'dateFormatter' },
        { key: 'messages', sortable: false }
      ]
    }
  },
  async mounted () {
    const response = await this.axios.get('/reports/rooms')
    this.allLivestreamRooms = response.data
    this.selectedRoom = this.allLivestreamRooms[0]
    this.loadMore()
  },
  watch: {
    selectedRoom: debounce(function (newVal) {
      this.currentPage = 1
      this.filterRoom()
    }, 500),
    reportedUserFilter: debounce(function (newVal) {
      this.currentPage = 1
      this.filterReportedUser(newVal)
    }, 500)
  },
  methods: {
    platformBlock (username, id, index) {
      this.userToblock = {
        username,
        id,
        chatRoomId: 'all'
      }

      this.searchUsers[index].confirmation = true
      console.log('platformBlock', this.userToblock)
    },
    async confirmPlatformBlock (index) {
      try {
        await this.axios.post('/users/blocked', { user: this.userToblock })
      } catch (err) {
        console.log(err)
      }

      await this.$pnPublish({
        channel: `user.blockGlobal.${this.userToblock.id}`,
        message: { type: 'blocked', room: 'all' }
      },
      (status, response) => console.log(status, response))
      this.userToblock = {}

      this.searchUsers[index].confirmation = false
      this.searchUsers[index].blocked.push('all')
    },
    cancelPlatformBlock (index) {
      this.searchUsers[index].confirmation = false
      this.userToblock = {}
    },
    async platformUnblock (username, id, index) {
      this.userToUnblock = {
        username,
        id,
        chatRoomId: 'all'
      }

      this.searchUsers[index].confirmation = true
    },
    async confirmPlatformUnblock (index) {
      try {
        await this.axios.delete(`/users/blocked/${this.userToUnblock.id}`, { data: { idType: 'user' } })
      } catch (err) {
        console.log(err)
      }

      await this.$pnPublish({
        channel: `user.blockGlobal.${this.userToUnblock.id}`,
        message: { type: 'unblocked', room: 'all' }
      },
      (status, response) => console.log(status, response))
      this.userToUnblock = {}

      this.searchUsers[index].confirmation = false
      this.searchUsers[index].blocked = [] // all rooms are removed
    },
    cancelPlatformUnblock (index) {
      this.searchUsers[index].confirmation = false
      this.userToUnblock = {}
    },
    showMessagesModal (fullData) {
      this.modalMessages = fullData.messages
      this.$refs.showMessages.show()
    },
    async getMatchingUsers (query) {
      if (query === '') { return }
      this.$store.commit('isLoading', true)
      this.queryObject.q = query
      const usersResponse = await this.getUsers(this.queryObject)
      this.searchUsers = usersResponse.data
      this.searchUsers.forEach((item) => {
        this.$set(item, 'confirmation', false)
      })

      this.$refs.findUser.show()
      this.$store.commit('isLoading', false)
    },
    getUsers (queryStringParams) {
      let url = '/users'
      if (queryStringParams) {
        url += '?'
        let hasGoneOnce = false
        for (const key in queryStringParams) {
          if (!hasGoneOnce) {
            hasGoneOnce = true
          } else {
            url += '&'
          }
          url += key + '=' + encodeURIComponent(queryStringParams[key])
        }
      }
      return this.axios.get(url)
    },
    getFormattedQuery () {
      if (this.reportedUserFilter) {
        return `/reports?page=${this.currentPage}&room=${this.selectedRoom}&user=${this.reportedUserFilter}`
      } else {
        return `/reports?page=${this.currentPage}&room=${this.selectedRoom}`
      }
    },
    async filterReportedUser () {
      const formattedQuery = this.getFormattedQuery()
      // console.log(formattedQuery)
      const reportResponse = await this.axios.get(formattedQuery)
      this.$nextTick(() => {
        this.reports = reportResponse.data.data
      })
    },
    dateFormatter (value) {
      // console.log('date', value)
      const reportedDate = new Date(value)
      // console.log('res', `${reportedDate.toDateString()} ${reportedDate.toTimeString()}`)
      const formattedDate = this.moment(reportedDate).format('MMMM Do YYYY, h:mm:ss a')
      return formattedDate
    },
    async filterRoom (query) {
      const formattedQuery = this.getFormattedQuery()
      // console.log(formattedQuery)
      const reportResponse = await this.axios.get(formattedQuery)
      this.$nextTick(() => {
        this.reports = reportResponse.data.data
      })
    },
    changePage () {
      this.currentPage++
      this.loadMore()
    },
    handleScroll () {
      const listElm = this.$refs.ifList
      const loadThreshold = listElm.$el.scrollHeight - 50 // start loading 50px advance
      // when offsetHeight + scrollTop = scrollHeight (reached end of the list)
      if (listElm.$el.scrollTop + listElm.$el.offsetHeight > loadThreshold) {
        this.showLoadMore = true
      } else {
        this.showLoadMore = false
      }
    },
    async loadMore () {
      this.busy = true
      const formattedQuery = this.getFormattedQuery()
      // console.log(formattedQuery)
      const reportResponse = await this.axios.get(formattedQuery)
      this.$nextTick(() => {
        this.showLoadMore = false
        // console.log(reportResponse.data.data)
        this.reports = this.reports.concat(reportResponse.data.data)
        this.busy = false
      })
    }
  }
}
</script>

<style>
/* Busy table styling */
table.b-table[aria-busy='true'] {
  opacity: 0.6;
}

.b-table-sticky-header{
  opacity: 1;
}

.load-more {
  width: 100%;
  margin-bottom: 40px;
  background-color: #182028;
  border-color: #182028;
}

.load-more:hover {
  background-color: #2b3946;
}

ul {
  margin: 0;
  padding: 0;
}
</style>

<style scoped lang="scss">
.btn-global-find {
  background-color: #182028;
  border-color: #182028;
}

.btn-global-find:hover {
  background-color: #2b3946;
}

.btns-wrapper {
  display: flex;
  align-items: center;
}

.sm-date {
  font-size: 10pt;
}

.info-text{
  font-size: 10px;
  color: red;
  margin-bottom: 0px;
  margin-top: 5px;
}

#findUser h4 {
  margin-bottom: 20px;
}

#findUser h4 span {
  color: grey;
}

#findUser .user-found-wrapper .user-found {
  padding: 15px 0;
}

#findUser .user-found-wrapper .user-found .username,
#findUser .user-found-wrapper .user-found .email {
  font-weight: 700;
  font-size: 15px;
}

#findUser .user-found-wrapper .user-found .id {
  color: grey;
  font-size: 13px;
  font-weight: normal
}

.btn-block {
  margin-top: 5px;
  margin-right: 5px;
  font-size: 12px;
  color: #fff;
  background-color: #182028;
  font-weight: 600;
  border-radius: 3px;
  cursor: pointer;
  transition: all .3s ease;
  max-width: 80px;
  display: flex;
  justify-content: center;
  padding: 4px 0;
}

.btn-block.btn-messages {
  max-width: 150px;
}

.btn-block:hover {
  background-color: #2b3946;
}

.confirmation-wrapper .btn-block:first-child {
  margin-right: 10px;
  background-color: grey;
}

.messages-history-list {
  margin-top: 20px;
}

.messages-history-list ul li {
  margin-bottom: 15px;
}
</style>
