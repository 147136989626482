<template>
    <div class="d-flex flex-row justify-content-between contest-item">
        <div @click="selectPlay(play.promo_plays_id)" class="d-flex flex-column contest-pointer">
        <div class="d-flex flex-row align-items-center">
            <MdRadioButtonOnIcon v-if="play.isActive" style="color: red;" animate="beat"/>
            <div class="contest-title">{{  play.prize_name }}</div>
        </div>
        <p v-if="play.winners_name" style="color: green;">Winner: {{ play.winners_name }}</p>
        <div style="font-size: small;"> {{ formattedDate(play.run_date) }} </div>
        </div>
        <div class="d-flex flex-row align-items-center">
        <b-button variant="success" @click="publishPlay(play.promo_plays_id,!play.isActive)">{{publishButtonText(play.isActive)}}</b-button>
        </div>
    </div>
</template>

<script >
import MdRadioButtonOnIcon from 'vue-ionicons/dist/md-radio-button-on.vue'

export default {
  name: 'SinglePlay',
  components: {
    MdRadioButtonOnIcon
  },
  props: {
    play: Object
  },
  methods: {
    formattedDate (date) {
      const d = Date.parse(date)
      return this.moment.utc(d).format('l, h:mm:ss a')
    },
    publishPlay (playId, shouldPublish) {
      this.$emit('publishPlayState', playId, shouldPublish)
    },
    selectPlay (playId) {
      this.$emit('selectPlayState', playId)
    },
    publishButtonText (isActive) {
      if (isActive) {
        return 'Unpublish Play'
      }
      return 'Publish Play'
    }
  }
}
</script>

<style scoped>
  .contest-item {
    margin-bottom: 10px;
    background-color: #f1f3f4;
    border-radius: 5px;
    padding: 10px;
  }

  .contest-item .contest-pointer{
    cursor: pointer;
  }

  .contest-item .contest-title{
    font-weight: bold;
  }
</style>
