<template>
  <b-container fluid class="full-page">
    <div class="full-page-wrapper">
      <b-row sm="3" align-h="center">
        <MdChatbubblesIcon w="100px" h="100px"/>
      </b-row>
      <b-row align-h="center" align-v="center" >
        <b-col sm="3">
          <b-form @submit="reset">
            <b-form-group
                id="input-group-1"
                label="Enter Password:"
                label-for="input-1">
                <b-form-input
                id="input-1"
                v-model="password.first"
                type="text"
                placeholder="Enter Password"
                required
                />
            </b-form-group>
            <b-form-group
                id="input-group-3"
                label="Repeat Password:"
                label-for="input-3">
                <b-form-input
                id="input-3"
                v-model="password.second"
                type="text"
                placeholder="Repeat Password"
                required
                />
            </b-form-group>
            <p class="error" v-if="error">{{ error }}</p>
            <p class="success" v-if="success">{{ success }}</p>
            <b-form-group>
              <b-button type="submit" class="btn-block main-btn">Submit</b-button>
            </b-form-group>
             <router-link v-if="success" class="btn btn-secondary btn-block" to="/login">Log In</router-link>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import MdChatbubblesIcon from 'vue-ionicons/dist/md-chatbubbles.vue'

export default {
  name: 'NewPassword',
  components: {
    MdChatbubblesIcon
  },
  data () {
    return {
      error: false,
      success: false,
      token: '',
      userID: '',
      password: {
        first: '',
        second: ''
      }
    }
  },
  async mounted () {
    this.userID = this.$route.params.userID
    this.token = this.$route.params.token

    try {
      const res = await this.axios.post('/auth/validate-link', { userID: this.userID, token: this.token })

      if (!res.data.success) {
        this.$router.push('/login')
      }
    } catch (err) {
      console.log(err)
      this.$router.push('/login')
    }
  },
  methods: {
    async reset (evt) {
      evt.preventDefault()

      if (this.password.first !== this.password.second) {
        this.error = 'Passwords should match. Please try again :('
        return
      }

      try {
        await this.axios.post('/auth/change-password', { userID: this.userID, password: this.password.first })

        this.error = false
        this.success = 'Password successfully updated. Please try to login.'
        this.password.first = ''
        this.password.second = ''
      } catch (err) {
        this.success = false
        this.error = 'Something went wrong. Please try again :('
        console.log(err)
      }
    }
  }
}
</script>

<style scoped>
.full-page{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.full-page-wrapper {
  flex: 1;
}
</style>
