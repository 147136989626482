<template>
    <div>
      <b-row>
        <b-col cols="8">
          <b-button class="px-1" @click="refreshDrawing" >
            <MdRefreshIcon w="22px" h="20px"/>
          </b-button>
          <b-button variant="success my-4 mx-1" :disabled="!canDrawWinner"  @click="validateWinner">Draw Winner</b-button>
          <b-button variant="info my-4 mx-1" :disabled="winWrapper==null" @click="notifyWinnerClaim">Notify Current Winner</b-button>
        </b-col>
      </b-row>

      <!-- participant list -->
      <b-row>
        <b-col>
          <h5>Participants</h5>
          <div class="d-flex align-items-center">
            <div class="pr-2 d-flex flex-column part-label">
              <p class="m-0 p-0">Total participants: {{ participantCount }} </p>
              <p class="m-0 p-0" v-b-tooltip.hover title="The number of participants eligible to be drawn as a winner">Valid participants: {{ validParticipantCount }} </p>
            </div>
            
            <p class="pr-2 m-0">&#9679; Refreshing list in {{ $store.getters.getParticipantPollTimer }}s </p>
            <p v-if="winnerDrawEnableInHours>0" class="m-0">&#9679; Winner re-draw available in {{ winnerDrawEnableInHours }}hr</p>
          </div>
          <div v-html="participantNames" @click="clickOnName" class="d-flex flex-wrap"> </div>

          <div class="d-flex query-container">
            <b-form-input v-model="participantQuery" placeholder="Search participants by username or id..."></b-form-input>
            <b-button @click="searchParticipants"> Find</b-button>
            <b-button @click="resetParticipantSearch"> Reset </b-button>
          </div>
          <div v-if="participantResult.length<1" class="text-center ">
            {{ getSearchResponse }}
          </div>
          <SearchCard v-else :participants="participantResult"/>
        </b-col>
      </b-row>

      <b-row v-if="winWrapper && winWrapper.winner" class="my-4">
        <b-col>
          <hr>
          <h5 class="text-center">Winner</h5>
          <div v-if="winWrapper && winWrapper.winner">
            <div class="d-flex flex-column mt-4 align-items-center">
              <div>
                <div> User Name: {{ winWrapper.winner.chat.username }} </div>
                <div> User Id: {{ winWrapper.winner._id }} </div>
                <div> User Email: {{ winWrapper.participation.email }} </div>
                <div> Drawn At: {{ moment(winWrapper.drawn_at).format('MMMM Do YYYY, h:mm:ss a') }} </div>
                <div v-if="winWrapper.claimed">
                  <hr>
                  <div> Claimed: {{ winWrapper.claimed  }} </div>
                  <div> Claimed At: {{ moment(winWrapper.claimed_at).format('MMMM Do YYYY, h:mm:ss a')  }} </div>
                </div>
              </div>
            </div>

          </div>

          <div v-else>
            <h5>No Winners Selected</h5>
          </div>
        </b-col>
      </b-row>
    </div>
</template>

<script>
import MdRefreshIcon from 'vue-ionicons/dist/md-refresh.vue'
import SearchCard from './contest/SearchCard'

export default {
  name: 'Drawing',
  props: {
    play: Object,
    contest: Object,
    timerId: Number
  },
  components:{
    MdRefreshIcon,
    SearchCard
  },
  computed:{
    winnerDrawEnableInHours(){
      let diff = -1
      if(this.winWrapper && this.winWrapper.winner && this.winWrapper.claimed === false){
        const drawnAt = this.moment(this.winWrapper.drawn_at)
        const now = this.moment()
        const hrsPassed = now.diff(drawnAt, 'hours')
        diff = this.claimLimit - hrsPassed
      }

      return diff
    },
    canDrawWinner(){
      if(this.validParticipantCount < 1){
        return false
      }else if(!this.winWrapper) { // no winner yet
        return true
      }else if(this.winWrapper && this.winWrapper.winner && this.winWrapper.claimed === false){
        const drawnAt = this.moment(this.winWrapper.drawn_at)
        const now = this.moment()
        const diff = now.diff(drawnAt, 'hours')
        if(diff>=this.claimLimit){
          return true
        }
      }
      return false
    },
    getSearchResponse(){
      return this.searchResponse[this.searchState]
    },
    participantNames(){
      let span = ''
      for (const participant of this.participants) {
        span +=`<span id="${participant.username}" style="color: blue; cursor: pointer;"> ${participant.username}</span>, `
      }
      if(this.participantCount>0){
        span += 'has participated'
      }else if(this.participantCount>5){
        span += 'and few others participated'
      }
      return span
    }
  },
  data () {
    return {
      participants: [],
      winWrapper: null,
      vcreativeData: null,
      participantQuery: '',
      participantResult: [],
      participantCount: 0,
      validParticipantCount: 0,
      searchResponse:[
      'Lookup participants by username or id....',
      'Cannot find a participant matching the query'
      ],
      searchState:0, // 0: Reset, 1: Searched
      claimLimit: 72,
    }
  },
  async mounted () {
    try {
      await this.getParticipants()
      await this.getWinners()
      if(this.contest.claim_limit){
        this.claimLimit = this.contest.claim_limit
      }
    } catch (err) {
      console.log('error')
      console.log(err)
    }
  },
  watch:{
    '$store.getters.getParticipantPollTimer': function() {
    if(this.$store.getters.getParticipantPollTimer === 0){
      this.refreshDrawing()
    }
  }
  },
  methods: {
    clickOnName(e){
      this.participantQuery = e.target.id
      this.searchParticipants()
    },
    resetParticipantSearch(){
      this.participantQuery = ''
      this.participantResult = []
      this.searchState = 0
    },
    async searchParticipants(){
      if(this.participantQuery.length<2){
        return
      }
      const searchUrl = `/participants?q=${this.participantQuery}&c=${this.play.contestId}&p=${this.play.promo_plays_id}`
      const searchResponse = await this.axios.get(searchUrl)
      this.participantResult = searchResponse.data.data
      if(this.participantResult.length<1){
        this.searchState = 1
      }
    },
    async refreshDrawing(){
      await this.getParticipants()
      await this.getWinners()
    },
    getRandomInt (max) {
      return Math.floor(Math.random() * max)
    },
    async notifyWinnerClaim () {
      const claimId = this.winWrapper._id
      this.$store.commit('isLoading', true)
      if(!claimId || claimId===''){
        this.makeToast('danger', 'Invalid Claim Id', 'Contact support!')
        return
      }

      const urlEmail = `/contests/notify?cid=${claimId}`
      const urlPush = `/contests/notify-push?cid=${claimId}`
      try {
        const emailResponse = await this.axios.get(urlEmail)
        const pushResponse = await this.axios.get(urlPush)
        this.$store.commit('isLoading', false)
        if (emailResponse.data.success) {
          this.makeToast('success', 'Winner Notified', 'An email was sent to the winner')
        } else {
          this.makeToast('danger', 'Winner Notification Failed', 'Notification failed, please try again.')
        }

        if (pushResponse.data.success) {
          this.makeToast('success', 'Winner Notified', 'A push notification was sent to the winner')
        } else {
          this.makeToast('danger', 'Winner Notification Failed', 'Notification failed, please try again.')
        }
      } catch (error) {
        console.log(error);
        this.$store.commit('isLoading', false)
      }
      
    },
    async unpublishPlay() {
      const playObj = this.play
      const contestId = this.play.contestId 

      const res = await this.axios.post(`/contests/${contestId}/plays`, playObj)
      if (res.data.success){
        this.$emit('publishPlayState', playObj.promo_plays_id, false)
      }
    },
    async validateWinner () {
      this.$store.commit('isLoading', true)
      const url = `/contests/${this.play.contestId}/plays/${this.play.promo_plays_id}/winner-validate`
      const res = await this.axios.post(url)
      if (res.data.success) {
        // winner assigned successfully
        this.makeToast('success', 'Selection Successful', 'Winner has been selected')
        this.$store.commit('resetTimer')
        clearInterval(this.timerId)
        this.unpublishPlay()
        this.$store.commit('isLoading', false)
      } else {
        // failed to assign winner
        this.$store.commit('isLoading', false)
        this.makeToast('danger', 'Selection Rejected', 'The selection is rejected by the system, please draw winner again')
      }
      await this.refreshDrawing()
    },
    makeToast (variant, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 5000,
        variant: variant
      })
    },
    async addParticipants () {
      const randUser = await this.axios.get('/participants/random')
      const randomUser = randUser.data.data[0]

      const participant = {
        contest_id: this.play.contestId,
        plays_id: this.play.promo_plays_id,
        user_id: randomUser._id,
        fbid: randomUser.firebase_user_id,
        email: 'randomTestUser@gmail.com',
        username: randomUser.chat.username
      }
      const res = await this.axios.post('/participants', participant)
      if (res.data.success) {
        this.getParticipants()
      } else {
        this.makeToast('danger', 'Try Again', 'Selected participant rejected by server, please try again')
      }
    },
    async getParticipants () {
      const res = await this.axios.get(`/contests/${this.play.contestId}/plays/${this.play.promo_plays_id}/participants`)
      this.participantCount = res.data.data.count.total
      this.validParticipantCount = res.data.data.count.valid
      this.participants = res.data.data.items
    },
    async getWinners () {
      const res = await this.axios.get(`/contests/${this.play.contestId}/plays/${this.play.promo_plays_id}/winner`)
      const winnerWrapper = res.data.data
      if (winnerWrapper && winnerWrapper.length > 0) {
        this.$store.commit('resetTimer')
        clearInterval(this.timerId)
        this.winWrapper = winnerWrapper.pop()
      }
    }
  }
}
</script>
<style scoped>
.query-container{
  gap: 4px;
  margin-bottom: 16px;
}

.part-label{
  font-size: smaller;
}
</style>