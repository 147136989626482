<template>
  <b-container fluid class="full-page">
    <div class="full-page-wrapper">
      <b-row sm="3" align-h="center">
        <MdChatbubblesIcon w="100px" h="100px"/>
      </b-row>
      <b-row align-h="center" align-v="center" >
        <b-col sm="3">
          <b-form @submit="reset">
            <b-form-group
                id="input-group-1"
                label="Email address:"
                description="A password reset link will be sent to this email address"
                label-for="input-1">
                <b-form-input
                id="input-1"
                v-model="email"
                type="email"
                placeholder="Enter email"
                required
                />
            </b-form-group>
            <p class="error" v-if="error">{{ error }}</p>
            <p class="success" v-if="success">{{ success }}</p>
            <b-form-group>
              <b-button type="submit" class="btn-block main-btn">Reset Password</b-button>
            </b-form-group>
            <router-link class="btn btn-secondary btn-block" to="/login">Log In</router-link>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import MdChatbubblesIcon from 'vue-ionicons/dist/md-chatbubbles.vue'

export default {
  name: 'ForgotPassword',
  components: {
    MdChatbubblesIcon
  },
  data () {
    return {
      error: false,
      success: false,
      email: ''
    }
  },
  methods: {
    async reset (event) {
      event.preventDefault()

      try {
        await this.axios.post('/auth/forgot', { email: this.email })

        this.error = false
        this.success = 'Check your email to change your password'
        this.email = ''
      } catch (err) {
        this.success = false
        this.error = 'Something went wrong. Please try again :('
        console.log(err)
      }
    }
  }
}
</script>

<style scoped>
.full-page{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.full-page-wrapper {
  flex: 1;
}
</style>
