<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8">
          <h4 class="header">Manage Users</h4>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <b-table :items="users" :fields="fields" striped bordered>

            <template #cell(fullname)="data">
              <div v-if="data.item.isEditing" class="form-inline">
                <b-form-input class="m-1" type="text" placeholder="First Name" v-model="data.item.name"></b-form-input>
                <b-form-input class="m-1" type="text" placeholder="Last Name" v-model="data.item.lastname" ></b-form-input>
              </div>

              <div v-else>
                {{data.item.name}} {{data.item.lastname}}
              </div>
            </template>

           <template #cell(controls)="data">
              <b-button v-if="!data.item.isEditing" @click="changeIsEditing(data.item)">Edit</b-button>
              <div v-else>
                <b-button class="mx-2" @click="confirmEdits(data.item)">Confirm</b-button>
              </div>
            </template>

            <template v-slot:cell(email)="data">
              {{data.item.email}}
            </template>

            <template v-slot:cell(isAdmin)="data">
              <b-form-checkbox
                v-model="data.item.isAdmin"
                :disabled="!data.item.isEditing"
                name="checkbox-admin">
              </b-form-checkbox>
            </template>

            <template v-slot:cell(isActive)="data">
              <b-form-checkbox
                v-model="data.item.isActive"
                :disabled="!data.item.isEditing"
                name="checkbox-1">
              </b-form-checkbox>
            </template>

            <template v-slot:cell(permissions)="data">
              {{data.permission}}
              <b-button :disabled="!data.item.isEditing" @click="fetchLivestreams()" >Livestreams</b-button>
              <b-button :disabled="!data.item.isEditing" @click="fetchStations()" class="ml-2 px-1">Stations</b-button>
            </template>

          </b-table>
        </div>
      </div>
    </div>

    <b-modal id="modal-permission"
    @ok="modalSave()"
    title="Permissions">

      <b-list-group class="mt-4">
          <b-form-checkbox-group
            id="cb-group"
            v-model="permissions[fetchedResourceType]"
            name="cb-group"
          >
            <div v-for="eachResource of allResourceList"
            :key="eachResource._id">
              <b-form-checkbox
                v-if="fetchedResourceType ==='stations'"
                :value="eachResource._id">
                <b-img width="120px" :src="eachResource.exploreImagery.mdpiImageURL"></b-img>
                {{eachResource.title}}
              </b-form-checkbox>

              <b-form-checkbox
                v-else
                :value="eachResource._id">
                <b-img width="120px" :src="eachResource.img"></b-img>
                {{eachResource.titleEnglish}}
              </b-form-checkbox>
              <hr>
            </div>

          </b-form-checkbox-group>
      </b-list-group>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'Manage',
  data () {
    return {
      users: [],
      allResourceList: [],
      fetchedResourceType: '',
      options: { stations: [], livestreams: [] },
      permissions: { stations: [], livestreams: [] },
      status: true,
      resourceEditable: null,
      resourceEditableIndex: -1,
      fields: [
        {
          key: 'controls'
        },
        {
          key: 'fullName',
          sortable: true
        },
        {
          key: 'email',
          sortable: true
        },
        {
          key: 'isAdmin'
        },
        {
          key: 'isActive'
        },
        {
          key: 'permissions'
        }
      ]
    }
  },
  async mounted () {
    const userResponse = await this.axios.get('/profiles', { cache: false })
    this.users = userResponse.data
    // adding editable trait
    this.users.forEach(element => {
      element.isEditing = false
    })
  },
  methods: {
    async modalSave () {
      const editableRes = this.users[this.resourceEditableIndex]
      editableRes.permissions = this.permissions
      if (this.resourceEditableIndex > -1) {
        this.$set(this.users, this.resourceEditableIndex, editableRes)
      }
    },
    async fetchLivestreams () {
      this.fetchedResourceType = 'livestreams'
      const livestreams = await this.axios.get('/profiles/livestreams')
      this.allResourceList = livestreams.data.data
      for (const eachStream of this.allResourceList) {
        this.options.livestreams.push({
          value: eachStream._id,
          text: eachStream.titleEnglish
        })
      }
      this.$bvModal.show('modal-permission')
    },
    async fetchStations () {
      this.fetchedResourceType = 'stations'
      const stations = await this.axios.get('/profiles/stations')
      this.allResourceList = stations.data.data
      for (const eachStream of this.allResourceList) {
        this.options.stations.push({
          value: eachStream._id,
          text: eachStream.title
        })
      }
      this.$bvModal.show('modal-permission')
    },
    changeIsEditing (value) {
      const itemIndex = this.users.indexOf(value)
      value.isEditing = !value.isEditing

      this.permissions = value.permissions
      this.resourceEditableIndex = itemIndex
      if (itemIndex > -1) {
        this.$set(this.users, itemIndex, value)
      }
    },
    async confirmEdits (value) {
      const itemIndex = this.users.indexOf(value)
      value.isEditing = !value.isEditing
      if (itemIndex > -1) {
        console.log('bEdit', value)
        this.$set(this.users, itemIndex, value)
        // save on db
        const serverData = value
        delete serverData.isEditing
        await this.axios.put(`/profiles/${value._id}`, { updatedProfile: serverData })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  background-color: #182028;
  border-color: #182028;
}

.btn:hover {
  background-color: #2b3946;
}

.btn {
    margin-top: 5px;
    font-size: 12px;
    color: #fff;
    background-color: #182028;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
    transition: all .3s ease;
    max-width: 80px;
    padding: 4px;
  }

.btns-wrapper {
  display: flex;
  align-items: center;
}
</style>
