<template>
    <div>
        <div v-if="modalState === 'plays'">
          <h4>Plays</h4>
          <b-tabs content-class="mt-3">
            <b-tab title="Live" active>
              <div v-if="livePlay" >
                <SinglePlay :play="livePlay" @publishPlayState="publishPlay" @selectPlayState="selectPlay"/>
              </div>
              <p v-else>There's no live play in this contest</p>
            </b-tab>
            <b-tab title="Today">
              <div v-for="play of todaysPlays" :key="play.promo_plays_id">
                <SinglePlay :play="play" @publishPlayState="publishPlay" @selectPlayState="selectPlay"/>
              </div>
            </b-tab>
            <b-tab title="All Plays">
              <div v-for="play of modalData.plays" :key="play.promo_plays_id">
                <SinglePlay :play="play" @publishPlayState="publishPlay" @selectPlayState="selectPlay"/>
              </div>
            </b-tab>
            <b-tab title="Contest Settings">
              <div>
                <b-form-group
                label-cols="auto"
                label="*Terms & Conditions URL:"
                label-for="tc-url"
                >
                  <b-form-input id="tc-url" v-model="termsUrl" placeholder="Terms & Conditions URL"></b-form-input>
                </b-form-group>
                
                <b-form-group
                label-cols="auto"
                label="Claim Page URL:"
                label-for="claim-url"
                >
                  <b-form-input id="claim-url" v-model="claimUrl" placeholder="Claim Page URL"></b-form-input>
                </b-form-group>
                <b-form-group
                label-cols="auto"
                label="Claim Limit(Hours):"
                label-for="claim-limit"
                >
                  <b-form-input id="claim-limit" type="number" v-model="claimLimit" placeholder="Default is 72 hours"></b-form-input>
                </b-form-group>
                <b-button variant="success" @click="updateAdditionalUrl">Save</b-button>
              </div>
            </b-tab>
          </b-tabs>
        </div>
        <div v-else-if="modalState ==='drawing'">
          <div class="d-flex align-items-center">
            <b-button @click="backClick" variant="light" class="mx-1">
              <span><MdArrowBackIcon /></span>
            </b-button>
            <h4>Drawing {{ playDate }}</h4>
          </div>

          <Drawing :play="selectedPlay" :timerId="participantPollTimer" :contest="modalData.contest" @publishPlayState="publishPlay"/>
        </div>
    </div>
</template>

<script>
import Drawing from './Drawing.vue'
import SinglePlay from './SinglePlay.vue'
import MdArrowBackIcon from 'vue-ionicons/dist/md-arrow-back.vue'

export default {
  name: 'Plays',
  components: {
    Drawing,
    SinglePlay,
    MdArrowBackIcon
  },
  props: {
    modalData: Object
  },
  computed: {
    playDate () {
      let mc = ''
      if (this.selectedPlay) {
        mc = this.moment(this.selectedPlay.run_date).calendar()
      }
      return mc
    }
  },
  data () {
    return {
      selectedPlay: {},
      modalState: 'plays', // plays | drawing
      todaysPlays: [],
      livePlay: {},
      termsUrl:'',
      claimUrl:'',
      claimLimit: null,
      participantPollTimer: null
    }
  },
  async mounted () {
    this.termsUrl = this.modalData.contest.terms_url
    this.updateView()
  },
  beforeDestroy(){
    this.$store.commit('resetTimer')
    clearInterval(this.participantPollTimer)
  },
  methods: {
    startParticipantPoll(){
      this.participantPollTimer = setInterval(() => {
      this.$store.commit('refreshTimer')
      }, 1000);
    },
    async updateAdditionalUrl(){
      const contestId = this.modalData.contest._id
      const url = `/contests/${contestId}`
      if(this.termsUrl.length<1){
        this.makeToast('danger','Error','Terms & Conditions URL Required')
        return
      }
      const editProperties = {
        termsUrl: this.termsUrl,
        claimUrl: this.claimUrl,
        claimLimit: this.claimLimit
      }
        const response = await this.axios.put(url, editProperties )
        if(response.data.success){
          this.makeToast('success','Contest Updated', 'new values are saved')
          this.$set(this.modalData.contest, 'terms_url', this.termsUrl)
          this.$set(this.modalData.contest, 'claim_url', this.claimUrl)
          this.$set(this.modalData.contest, 'claim_limit', this.claimLimit)
        }
    },
    makeToast (variant, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 5000,
        variant: variant
      })
    },
    async publishPlay (playId, shouldPublish) {
      const hasActiveContest = this.modalData.plays.findIndex((contest) => contest.isActive === true)

      if(!this.termsUrl || this.termsUrl===''){
        this.makeToast('danger', 'Error', 'You must set terms & conditions URL before publishing play')
        return
      }

      if (shouldPublish && hasActiveContest > 0) {
        this.makeToast('danger', 'Error', 'A play is already live in this contest')
        return
      }
      const play = this.modalData.plays.find((elem) => elem.promo_plays_id === playId)
      const contest = this.modalData.contest
      
      const res = await this.axios.post(`/contests/${contest._id}/plays`, play)
      if (res.data.success) {
        this.$set(play, 'isActive', shouldPublish)
      }
      
      // notify clients that you need to show participation button
      const pnData = {
        _id: this.modalData.contest._id,
        title: this.modalData.contest.title,
        start: this.modalData.contest.start,
        end: this.modalData.contest.end,
        terms_url: this.modalData.contest.terms_url,
        plays: [
          play
        ]
      }
      
      this.$pnPublish({
        channel: `contests.${contest.station_id}`,
        message: pnData
      })

      if (contest.additional_resources_id && contest.additional_resources_id.length > 0) {
        for (const eachStationId of contest.additional_resources_id) {
          const notify = `contests.${eachStationId._id}`
          this.$pnPublish({
            channel: notify,
            message: pnData
          })
        }
      }

      this.$nextTick(function () {
        this.updateView()
      })
    },
    updateView () {
      this.termsUrl = this.modalData.contest.terms_url
      this.claimUrl = this.modalData.contest.claim_url
      this.claimLimit = this.modalData.contest.claim_limit
      const plays = this.modalData.plays
      this.todaysPlays.splice(0)
      const tplays = plays.filter((e) => {
        return this.moment(e.run_date).isSame(this.moment(), 'day')
      })
      this.todaysPlays = tplays
      this.livePlay = plays.filter((e) => e.isActive)[0]
    },
    publishButtonText (isActive) {
      if (isActive) {
        return 'Unpublish Play'
      }
      return 'Publish Play'
    },
    formattedDate (date) {
      const d = Date.parse(date)
      return this.moment(d).format('l, h:mm:ss a')
    },
    backClick () {
      this.$store.commit('resetTimer')
      clearInterval(this.participantPollTimer)
      this.modalState = 'plays'
    },
    selectPlay (playId) {
      this.selectedPlay = this.modalData.plays.find((elem) => elem.promo_plays_id === playId)
      this.selectedPlay.contestId = this.modalData.contest._id
      this.startParticipantPoll() 
      this.modalState = 'drawing'
    }
  }
}
</script>
