<template>
  <b-container fluid class="full-page">
      <div class="full-page-wrapper">
        <b-row sm="3" align-h="center">
          <MdChatbubblesIcon w="100px" h="100px"/>
        </b-row>
        <b-row align-h="center" >
          <b-col sm="3">
            <b-form @submit="login">
              <b-form-group
                  id="input-group-1"
                  label="Email address:"
                  label-for="input-1">
                  <b-form-input
                  id="input-1"
                  v-model="user.email"
                  type="email"
                  placeholder="Enter email"
                  required
                  />
              </b-form-group>
              <b-form-group
                  id="input-group-2"
                  label="Password:"
                  label-for="input-2">
                  <b-form-input
                  id="input-2"
                  v-model="user.password"
                  type="password"
                  placeholder="Enter Password"
                  required
                  />
              </b-form-group>
              <p class="error" v-if="error">{{ error }}</p>
              <b-form-group>
                  <b-button type="submit" class="btn-block main-btn">Log In</b-button>
              </b-form-group>
              <hr>
              <router-link class="btn btn-secondary btn-block" to="/register">Create an account</router-link>
              <router-link class="btn btn-secondary btn-block" to="/recover">Forgot Password</router-link>
            </b-form>
          </b-col>
        </b-row>
      </div>
  </b-container>
</template>

<script>
import MdChatbubblesIcon from 'vue-ionicons/dist/md-chatbubbles.vue'

export default {
  name: 'Login',
  components: {
    MdChatbubblesIcon
  },
  data () {
    return {
      error: false,
      user: {
        email: '',
        password: ''
      }
    }
  },
  mounted () {},
  methods: {
    async login (event) {
      event.preventDefault()

      try {
        const res = await this.axios.post('/auth/login', { user: this.user })
        if (!res.data.success) {
          this.error = res.data.message
          return
        }
        // console.log('res', res.data.data)
        localStorage.setItem('token', res.data.data.token)
        this.$router.push('/')
      } catch (err) {
        this.error = 'Something went wrong. Please try again :('
        console.log(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.full-page{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.full-page-wrapper {
  flex: 1;
}
</style>
