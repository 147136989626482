import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import BootstrapVue from 'bootstrap-vue'
import { uuid } from 'vue-uuid'
import VueRouter from 'vue-router'
import moment from 'moment'
import PubNubVue from 'pubnub-vue'

import Home from './components/Home'
import Livestream from './components/Livestream'
import Station from './components/Station'
import Reports from './components/Report'
import Login from './components/public/Login'
import Register from './components/public/Register'
import ForgotPassword from './components/public/ForgotPassword'
import NewPassword from './components/public/NewPassword'
import Manage from './components/Manage'
import Contests from './components/Contests'

import getEnv from './../config/getEnv'
const config = getEnv()

function getBaseURL () {
  let BASE_URL
  switch (window.location.hostname) {
    case 'localhost':
      BASE_URL = config.BASE_URL
      break
    case 'chat.lamusica.com':
      BASE_URL = 'https://chat-api.lamusica.com'
      break
    case 'chat.ygvsolutions.com':
      BASE_URL = 'http://chat-api.ygvsolutions.com'
      break
    case 'chat-dev.lamusica.com':
      BASE_URL = 'http://chat-api-dev.lamusica.com'
      break
    case 'chat-stage.lamusica.com':
      BASE_URL = 'http://chat-api-stage.lamusica.com:3010'
      break
    default:
      BASE_URL = null
  }
  return BASE_URL
}

const axiosInstance = axios.create({
  baseURL: getBaseURL()
})

console.log(uuid.v4())
Vue.use(PubNubVue, {
  subscribeKey: config.PUBNUB_SUB_KEY,
  publishKey: config.PUBNUB_PUB_KEY,
  ssl: false,
  restore: true,
  uuid: 'lamusica-moderator'
  // logVerbosity: true
})

Vue.use(BootstrapVue)
Vue.use(VueAxios, axiosInstance)
Vue.use(VueRouter)
require('vue-ionicons/ionicons.css')
Vue.prototype.moment = moment

Vue.use(Vuex)
Vue.config.productionTip = false

const store = new Vuex.Store({
  state: {
    isLoading: false,
    isLoggedIn: false,
    isAdmin: false,
    participantPollTime:30
  },
  mutations: {
    isLoading (state, isLoading) {
      state.isLoading = isLoading
    },
    isLoggedIn (state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn
    },
    isAdmin (state, isAdmin) {
      state.isAdmin = isAdmin
    },
    // should be called every second
    refreshTimer(state) {
      if(state.participantPollTime > 0){
        state.participantPollTime--
      }else{
        state.participantPollTime = 30
      }
    },
    resetTimer(state) {
      state.participantPollTime = 30
    }
  },
  getters: {
    isLoading: state => state.isLoading,
    isLoggedIn: state => state.isLoggedIn,
    isAdmin: state => state.isAdmin,
    getParticipantPollTimer: state => state.participantPollTime
  }
})

const routes = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/recover',
    component: ForgotPassword
  },
  {
    path: '/recover/:userID/:token',
    component: NewPassword
  },
  {
    path: '/',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/livestreams/:id',
    component: Livestream,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/stations/:id',
    component: Station,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reports',
    component: Reports,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/manage',
    component: Manage,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/contests',
    component: Contests,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = localStorage.getItem('token')

    if (token === null) {
      store.commit('isLoggedIn', false)
      next({
        path: '/login'
      })
    } else {
      try {
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
        const res = await axiosInstance.post('/auth/validate')

        if (res.data.success) {
          store.commit('isLoggedIn', true)
          const isAdmin = res.data.data.isAdmin
          store.commit('isAdmin', isAdmin)

          if (to.meta.requiresAdmin) {
            if (isAdmin) {
              next()
            } else {
              return false
            }
          }

          next()
        } else {
          localStorage.removeItem('token')
          axiosInstance.defaults.headers.common.Authorization = 'Bearer'
          store.commit('isLoggedIn', false)
          next({
            path: '/login'
          })
        }
      } catch (err) {
        console.log(err)
        localStorage.removeItem('token')
        axiosInstance.defaults.headers.common.Authorization = 'Bearer'
        store.commit('isLoggedIn', false)
        next({
          path: '/login'
        })
      }
    }
  } else {
    next()
  }
})

new Vue({
  store,
  render: h => h(App),
  router
}).$mount('#app')
