<template>
    <div>
        <div v-for="participant of participants" :key="participant._id">
            <b-card rounded :class="userCardClass">
              
              <b-card-text v-if="showCardDetail">
                <b-row>
                  <b-col v-if="cardDetail.participations && cardDetail.participations.length>0">
                      <!-- Previous participations column -->
                      <div class="details-title">Previous Participations:</div>
                      <div v-for="eachPart of cardDetail.participations" :key="eachPart._id">
                          <div class="d-flex flex-column details-body">
                              <div>Contest: {{ eachPart.contest_title }}</div>
                              <div>Prize: {{ eachPart.prize_name }}</div>
                              <div>Play On: {{ moment(eachPart.play_date).format('MMMM Do YYYY, h:mm:ss a') }}</div>
                              <div>Participated On: {{ moment(eachPart.participation_date).format('MMMM Do YYYY, h:mm:ss a') }}</div>
                          </div>
                          <hr>
                      </div>
                      
                    </b-col>
                  <b-col v-if="cardDetail.wins && cardDetail.wins.length>0">
                      
                      <div class="details-title">Previous Wins:</div>
                      <div v-for="eachWin of cardDetail.wins" :key="eachWin._id">
                            <div class="d-flex flex-column details-body">
                                <div> Drawn At: {{ moment(eachWin.drawn_at).format('MMMM Do YYYY, h:mm:ss a') }} </div>
                                <div> Claimed: {{ eachWin.claimed  }} </div>
                                <div v-if="eachWin.claimed" > Claimed At: {{ moment(eachWin.claimed_at).format('MMMM Do YYYY, h:mm:ss a')  }} </div>
                            </div>
                            <hr>
                      </div>

                    </b-col>
                  <b-col>
                      
                      <div class="details-title">Profile:</div>
                        <div class="d-flex flex-column details-body">
                            <div>ID: {{ cardDetail.profile[0]._id }} </div>
                            <div>Email: {{ cardDetail.profile[0].email }} </div>
                            <div>Username: {{ cardDetail.profile[0].chat.username }} </div>  
                            <div>Gender: {{ cardDetail.profile[0].gender }} </div>  
                            <div>DoB: {{ moment(cardDetail.profile[0].date_of_birth).format('MMMM Do YYYY, h:mm:ss a')  }} </div>  
                            <div>Profile Created: {{ moment(cardDetail.profile[0].date_created).format('MMMM Do YYYY, h:mm:ss a')  }} </div>  
                        </div>
                        <hr>
                    </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="see-more" @click="getCardsDetail(participant)"> See Less </div>
                  </b-col>
                </b-row>
                
              </b-card-text>
              
              <b-card-text v-else class="details-body">
                <div> Username: {{ participant.username }} </div>
                <div> UserId: {{ participant.user_id }} </div>
                <div> Participated At: {{ moment(participant.created_at ).format('MMMM Do YYYY, h:mm:ss a') }} </div>
                <div class="see-more" @click="getCardsDetail(participant)"> See More </div>
              </b-card-text>
            </b-card>

          </div>
    </div>
</template>

<script>
export default {
    name:'SearchCard',
    props:{
        participants: Array
    },
    data(){
        return{
            showCardDetail:false,
            cardDetail:null
        }
    },
    computed:{

    userCardClass(){
      return this.showCardDetail? 'user-card detail' : 'user-card'
    },
    },
    methods:{
      async getCardsDetail(participation){
      this.showCardDetail = !this.showCardDetail
      if(this.showCardDetail){
        const searchUrl = `/participants/${participation._id}`
        const searchResponse = await this.axios.get(searchUrl)
        this.cardDetail = searchResponse.data.data
        
      }
    },
    }

}
</script>

<style scoped>
.user-card{
  max-width: 320px;
}

.user-card.detail{
  max-width: 100%;
}
.see-more{
  cursor: pointer;
  color: blue;
  text-align: end;
}

.details-title{
  font-weight: 500;
}
.details-body{
  font-size: small;
}
</style>