<template>
  <div id="app">
    <b-navbar v-if="$store.state.isLoggedIn" toggleable="lg" type="dark" :sticky="true">
      <b-navbar-brand to="/">La Musica Chat</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/">Home</b-nav-item>
          <b-nav-item v-if="$store.state.isAdmin" to="/manage">Manage</b-nav-item>
          <b-nav-item v-if="$store.state.isAdmin" to="/reports">Reports</b-nav-item>
          <b-nav-item v-if="$store.state.isAdmin" to="/contests">Contests</b-nav-item>
          <b-nav-item @click="logoutUser">Logout</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <router-view class="views-wrapper" />

    <loading></loading>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import loading from './components/loading'

export default {
  name: 'app',
  components: {
    loading
  },
  data () {
    return {}
  },
  methods: {
    logoutUser () {
      localStorage.removeItem('token')
      this.$store.commit('isLoggedIn', false)
      this.$router.push('/login')
    }
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700');

  body {
    background-color: #fafafe;
  }

  #app {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #182028;
    font-size: 15px;
    background-color: #fafafe;
  }

  .main-btn {
    background-color: #182028;
    border-color: #182028;
  }

  .error {
    color: red;
    font-size: 12px
  }

  .success {
    color: #2ea12e;
    font-size: 12px;
  }

  .main-btn:hover {
    background-color: #2b3946;
  }

  .navbar-brand, h4 {
    font-size: 26px;
    font-family: 'Abril Fatface', cursive;
    line-height: inherit;
    margin: 0;
  }

  .header {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .navbar {
    background-color: #182028;
    color: #fff;
  }

  .navbar-collapse {
    justify-content: flex-end;
  }

  .navbar-dark .navbar-nav .nav-link {
    color: #fff;
  }

  .nav-link.router-link-exact-active.router-link-active {
    font-weight: bold;
    color: #fff;
  }
</style>
