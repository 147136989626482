<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8">
            <h4 class="header">Contests</h4>
            </div>
            <b-form inline class="d-flex d-flex-row align-items-center filter-options-container">
                <label >
                  Primary Station
                  <b-form-select id="station-filter" class="station-select"  v-model="ddSelection" :options="dropdownItems" size="sm" ></b-form-select>
                </label>
                
                <label >
                  Show Live Contests 
                  <b-form-checkbox v-model="loadLiveOnly" name="liveOnlyToggle" class="ml-2" switch/>
                </label>
            </b-form>
        </div>

        <div class="row">
          <div class="col-md-12">
            <b-table :items="shownContests" :fields="fields" striped bordered selectable
              @row-clicked="tableItemClick" ref="contestTable">

              <template #cell(station_id)="data">
                <div class="d-flex flex-row align-items-center">
                  <b-img rounded :src="data.value.img" width="50px"/>
                  <div class="pl-2">
                    {{ data.value.title}}({{ data.value.callSign}})
                  </div>
                </div>
              </template>

              <template #cell(additional_resources_id)="data">
                <div class="d-flex flex-row align-items-center">
                  <div v-for="stInfo of data.value" :key="stInfo._id">
                      <b-img rounded :src="stInfo.img" width="50px" :title="imgHoverText(stInfo.title, stInfo.callSign)" class="px-1"></b-img>
                  </div>
                </div>
              </template>

            </b-table>
          </div>
        </div>

      <!-- Edit Contest Modal-->
      <b-modal id="contest-edit-modal" title="Edit Contest" ok-title="Save" @ok="updateContest" @cancel="clearTableSelection">
        <b-row >
            <b-col>
              <p>
                <strong> Contest Title: </strong> {{ selectedContest.title }}
                <br>
                <span style="color:green" v-if="getContestStatus(selectedContest)"> <strong>Ongoing</strong> </span>
                <span v-else>Not Live</span>
              </p>

            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <p>
                  <strong>Start:</strong>
                  {{ selectedContest.start }}

                  <br>

                  <strong>End:</strong>
                  {{ selectedContest.end }}
                </p>
            </b-col>
        </b-row>

       <div class="mt-4"> Resources:</div>
        <b-row>
            <b-col>
                <div v-if="lmStations.length> 0" class="d-flex flex-column">

                  <div v-for="(resource, index) in lmStations" :key="resource._id">
                    <div v-if="resource.type==='station'" class="d-flex align-items-center">
                      <b-img :src="resource.exploreImagery.mdpiImageURL" width="70" thumbnail></b-img>
                      <div class="d-flex flex-column mx-2">
                        <div >{{ resource.title }} ({{resource.callSign}})</div>
                        <div class="sub-title"> Station </div>
                      </div>
                      <b-button variant="light" class="ml-auto" v-if="index>0" @click="removeResource(index)">
                        <MdRemoveCircleOutlineIcon class="del" w="18px" h="18px"></MdRemoveCircleOutlineIcon>
                      </b-button>
                    </div>
                    <div v-else class="d-flex align-items-center">
                      <b-img :src="resource.img" width="70" thumbnail></b-img>
                      <div class="d-flex flex-column mx-2">
                        <div >{{ resource.titleEnglish }}</div>
                        <div class="sub-title"> Livestream </div>
                      </div>
                      <b-button variant="light" class="ml-auto" v-if="index>0" @click="removeResource(index)">
                        <MdRemoveCircleOutlineIcon class="del" w="18px" h="18px"></MdRemoveCircleOutlineIcon>
                      </b-button>
                    </div>
                    <hr>
                  </div>
                </div>

                <div>
                  <label for="additional-select" class="select-hint">Hold Cmd(Mac) or Ctlr(windows) to select multiple at once</label>
                  <b-form-select id="additional-select" class="my-2" v-model="newResourcesSelected" :options="newResourcesOptions"
                  multiple :select-size="6"
                  ></b-form-select>
                  <b-button class="btn-block" @click="addResources">Add</b-button>
                </div>
            </b-col>
        </b-row>
      </b-modal>
    </div>
</template>

<script>
import MdRemoveCircleOutlineIcon from 'vue-ionicons/dist/md-remove-circle-outline.vue'

export default {
  name: 'Contests',
  components: {
    MdRemoveCircleOutlineIcon
  },
  data () {
    return {
      shownContests: [],
      allContests: [],
      filteredContests: [],
      selectedContest: {},
      lmStations: [],
      stations: [], // contains data for all the stations in db
      livestreams: [], // contains data for all the livestreams in db
      additionalStationOptions: [],
      newResourcesOptions: [],
      newResourcesSelected: [],
      fields: [
        {
          key: 'title',
          tdClass: 'align-middle'
        },
        {
          key: 'start',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'end',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'vcreative_prf_id',
          tdClass: 'align-middle'
        },
        {
          key: 'station_id',
          label: 'Primary Station',
          formatter: 'stationInfoFormatter'
        },
        {
          key: 'additional_resources_id',
          label: 'Additional Resources',
          formatter: 'multipleResourceFormatter'
        }
      ],
      dropdownItems: [{ value: 'All', text: 'All' }],
      ddSelection: {},
      loadLiveOnly: true
    }
  },
  watch: {
    loadLiveOnly (newVal) {
      if (this.ddSelection === 'All') {
        this.filterContestsByLive(this.allContests)
      }
      this.filterContestsByLive(this.filteredContests)
    },
    ddSelection (newVal) {
      this.filterContestsByStation(newVal)
    }
  },
  async mounted () {
    this.ddSelection = this.dropdownItems[0].value
    await this.getContests()
    await this.getStations()
    await this.getLivestreams()
  },
  methods: {
    imgHoverText (title, callSign) {
      return `${title}(${callSign})`
    },
    stationInfoFormatter (value) {
      const sInfo = this.stations.find((el) => el._id.toString() === value)
      if (sInfo) {
        const stationInfo = {
          _id: sInfo._id,
          title: sInfo.title,
          callSign: sInfo.callSign,
          img: sInfo.detailViewImagery.mdpiImageURL
        }
        return stationInfo
      }
      return ''
    },
    livestreamInfoFormatter (value) {
      const sInfo = this.livestreams.find((el) => el._id.toString() === value)
      if (sInfo) {
        const livestreamInfo = {
          _id: sInfo._id,
          title: sInfo.titleEnglish,
          callSign: sInfo.slug,
          img: sInfo.square_img
        }
        return livestreamInfo
      }
      return ''
    },
    multipleResourceFormatter(value){
      const msiFormatted = []
      if (value && value.length > 0) {
        for (const eachResource of value) {
          let stInfo = null
          if(eachResource.type === 'station'){
            stInfo = this.stationInfoFormatter(eachResource._id)
          }else{
            stInfo = this.livestreamInfoFormatter(eachResource._id)
          }
          if(stInfo){
            msiFormatted.push(stInfo)
          }
        }

      }
      return msiFormatted
    },
    async getContests () {
      const url = '/contests'
      const response = await this.axios.get(url)
      this.allContests = response.data.data
      this.filterContestsByLive(this.allContests)
    },
    filterContestsByStation (resource) {
      if (resource === 'All') {
        this.filterContestsByLive(this.allContests)
      } else {
        const stationId = resource._id
        this.filteredContests = this.allContests.filter((e) => {
          if (stationId === e.station_id ) {
            //|| (e.additional_station_id && e.additional_station_id.includes(stationId))
            return e
          }
        })
        this.filterContestsByLive(this.filteredContests)
      }
    },
    getContestStatus (contest) {
      const now = Date.now()
      const start = Date.parse(contest.start)
      const end = Date.parse(contest.end)
      if (now < start) {
        return 'Upcomming'
      } else if (now > start && now < end) {
        return 'Ongoing'
      } else if (now > start && now > end) {
        return 'Finished'
      }
      return ''
    },
    filterContestsByLive (contests) {
      this.filteredContests = contests
      if (this.loadLiveOnly) {
        this.filteredContests = contests.filter((e) => {
          const now = Date.now()
          const start = Date.parse(e.start)
          const end = Date.parse(e.end)
          if (now > start && now < end) {
            return e
          }
        })
      }
      this.shownContests = this.filteredContests
    },
    async getLivestreams(){
      const url = '/livestreams'
      const response = await this.axios.get(url)
      this.livestreams = response.data
      const dropdownGroup =  {
        label: 'Livestreams',
        options: []
      }
      for(const live of this.livestreams){
        const dropdownItem = {
          value: {_id:live._id, type:'livestream'},
          text: `${live.titleEnglish}`
        }
        dropdownGroup.options.push(dropdownItem)
      }
      this.newResourcesOptions.push(dropdownGroup)
    },
    async getStations () {
      const url = '/stations'
      const response = await this.axios.get(url)
      this.stations = response.data
      const dropdownGroup =  {
        label: 'Stations',
        options: []
      }
      for (const station of this.stations) {
        const dropdownItem = {
          value: {_id:station._id, type:'station'},
          text: `${station.title}(${station.callSign})`
        }
        dropdownGroup.options.push(dropdownItem)
      }
      this.newResourcesOptions.push(dropdownGroup)
      this.dropdownItems.push(dropdownGroup) 
    },
    addResources () {
      for(const eachRes of this.newResourcesSelected){  
        let res = null
        if(eachRes.type === 'station'){
          res = this.stations.find((el) => el._id === eachRes._id)
          res.type= 'station'
        } else{
          res = this.livestreams.find((el) => el._id === eachRes._id)
          res.type = 'livestream'
        }
        if(res && !this.lmStations.includes(res)){
          this.lmStations.push(res)
        }
      }
    },
    removeResource (index) {
      this.lmStations.splice(index, 1)
    },
    tableItemClick (item, index) {
      this.selectedContest = item
      let additionalStations = []
      let additionalLivestreams = []
      const station = this.stations.find((el) => el._id === item.station_id)

      if (item.additional_resources_id && item.additional_resources_id.length>0) {
        const allAdditionalResources = item.additional_resources_id
        for(const eachResource of allAdditionalResources){
          if(eachResource.type ==='station'){
            const s = this.stations.find((el) => el._id === eachResource._id)
            if(s){
              additionalStations.push(s)
            }
          }else{
            const l = this.livestreams.find((el) => el._id === eachResource._id)
            if(l){
              additionalLivestreams.push(l)
            }
          }
        }
      }
      this.lmStations = [station, ...additionalStations, ...additionalLivestreams]
      this.$bvModal.show('contest-edit-modal')
    },
    clearTableSelection () {
      this.$refs.contestTable.clearSelected()
    },
    async updateContest () {
      this.$refs.contestTable.clearSelected()
      if (this.lmStations.length > 0) {
        const resArray = this.lmStations.map((e) => ({_id: e._id,type: e.type }))
        resArray.shift() // removing the primary station
        const url = `/contests/${this.selectedContest._id}`
        const response = await this.axios.put(url, { resources: resArray })
        await this.getContests()
      }
    }
  }
}

</script>

<style scoped>
.sub-title{
  color: grey;
  font-size: x-small;
}
.select-hint{
  font-size: 9px;
  padding: 0px;
  margin: 0px;
}
.filter-options-container {
  padding-top: 30px;
}
.station-select{
  max-width: 200px;
  margin: 0px 12px;
}
.del{
  color:red;
  cursor:pointer;
}

/* Tooltip text */
.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
