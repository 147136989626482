<template>
  <div>
    <div class="container-fluid home-page">
      <div class="row" v-if="livestreams.length">
        <div class="col-sm-12">
          <div class="header">
            <h4>Livestream Shows</h4>
          </div>
        </div>
        <div class="col-sm-3" v-for="livestream in livestreams" :key="livestream.chatRoomID">
          <div class="items-list livestream-item" @click="navigate('livestreams', livestream._id)">
            <div class="title">{{ livestream.titleEnglish }}</div>
            <div class="slug">{{ livestream.chatRoomID }}</div>
            <b-badge v-if="livestream.is_running" variant="success">Live</b-badge>
          </div>
        </div>
      </div>
      <div class="row" v-if="stations.length">
        <div class="col-sm-12">
          <div class="header">
            <h4>Radio Stations</h4>
          </div>
        </div>
        <div class="col-sm-3" v-for="station in stations" :key="station._id">
          <div class="items-list station-item" @click="navigate('stations', station._id)">
            <div class="title">{{ station.title }}</div>
            <div class="slug">{{ station.chat.id }}</div>
          </div>
        </div>
      </div>
      <div class="row" v-if="!livestreams.length && !stations.length">
        <div class="col-md-12">
          <div class="header">
            <h4 class="text-center">Sorry! There are not live shows at the moment</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      livestreams: [],
      stations: []
    }
  },
  async mounted () {
    this.livestreams = await this.getLivestreams()
    this.stations = await this.getStations()
    for (const eachLive of this.livestreams) {
      console.log('l', eachLive.is_running)
    }
  },
  methods: {
    getLivestreams () {
      return this.axios.get('/livestreams').then((response) => {
        return response.data.sort((a, b) => {
          return a.is_running === true ? -1 : 1
        })
      })
    },
    getStations () {
      return this.axios.get('/stations').then((response) => {
        return response.data
      })
    },
    navigate (resource, id) {
      this.$router.push(`/${resource}/${id}`)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.items-list {
  height: 110px;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #f0f0f1;
  background-color: #fff;
  transition: all .3s ease;
}

.items-list:hover {
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%);
}

.items-list .title {
  font-weight: 700;
}

.items-list .slug {
  font-size: 12px;
}
</style>
