<template>
  <div>
    <chat v-if="resource.chatRoomId" :resource="resource" />
  </div>
</template>

<script>
import chat from './Chat.vue'

export default {
  name: 'Livestream',
  components: {
    chat
  },
  data () {
    return {
      resource: {
        title: null,
        type: 'livestream',
        chatRoomId: null,
        image: null,
        resourceId: null
      }
    }
  },
  async mounted () {
    const resourceId = this.$route.params.id
    const url = `/livestreams/${resourceId}`

    try {
      const response = await this.axios.get(url)
      this.resource.title = response.data.titleEnglish
      this.resource.chatRoomId = response.data.chatRoomID
      this.resource.image = response.data.images.row.xhdpiImageURL
      this.resource.chat_admin_id = response.data.chatAdminId
      this.resource.resourceId = resourceId
    } catch (error) {
      if (error.response.status === 401) {
        this.toast(error.response.data)
      }
    }
  },
  methods: {
    toast (message) {
      this.$bvToast.toast(`${message}`, {
        title: 'Error',
        solid: true
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
