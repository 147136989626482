const prodConfig = require('./prod.env')
const devConfig = require('./dev.env')

module.exports = function () {
  if (process.env.NODE_ENV === 'production') {
    console.log('Running on production environment')
    return prodConfig
  }

  console.log('Running on development environment')
  return devConfig
}
