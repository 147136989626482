<template>
  <div>
    <div class="container-fluid chat-page">
      <div class="row">
        <div class="col-md-12">
          <div class="header">
            <div class="image-wrapper">
              <img :src="resource.image" alt="" />
              <h4>{{ resource.title }}</h4>
            </div>

            <div class="user-search">
              <b-input-group>
                <b-form-input v-model="query" type="search" placeholder="Search User By ID or Username" autocomplete="off" @keyup.enter="getMatchingUsers(query)"/>
                <b-input-group-append>
                  <b-button variant="info" @click="getMatchingUsers(query)">Find</b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <div class="messages-wrapper">
            <div class="header">
              As new messages come in you will see this area populate
            </div>

            <ul class="messages" ref="messages" id="messages">
              <li v-for="msg in historyMessages" :key="msg.timetoken" :id="msg.timetoken">

              <div v-if="msg.message.id==='hr'" class="msg-row-divider-container">
                  Previous Messages
                  <hr>
              </div>

              <div v-else class="msg-wrapper" @click="blockUser(msg.message.username, msg.message.id)">
                <span>{{ msg.message.username }}</span>
                {{ msg.message.text }}
                <div class="mt-2 time-font">{{getFormattedDate(msg.timetoken)}}</div>
              </div>
              </li>

              <li v-for="msg in messages" :key="msg.timetoken" :id="msg.timetoken">
                <div class="msg-wrapper" @click="blockUser(msg.message.username, msg.message.id)">
                  <span>{{ msg.message.username }}</span>
                  {{ msg.message.text }}
                  <div class="mt-2 time-font">{{getFormattedDate(msg.timetoken)}}</div>
                </div>
              </li>
            </ul>
            <div class="footer">
              <b-form-input v-model="message" ref="message" id="input-message" placeholder="Your Message..." autocomplete="off" v-on:keyup.enter="sendMessage"></b-form-input>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="tabs-btn-wrapper">
            <div class="btn" v-for="tab in getTabsAsUser" :key="tab.type" @click="selectTab(tab.type)" :class="{ active: selectedTab === tab.type }">
              {{ tab.label }}
            </div>
          </div>

          <div class="blocked-users-wrapper" v-if="selectedTab === 'blockUsers'">
            <div class="search">
              <input type="search" autocomplete="off" placeholder="Find blocked user" class="form-control" v-model="searchTerm" />
            </div>
            <div class="body">
              <div class="users-wrapper" v-if="filterByUsername.length">
                <div class="user"  v-for="u in filterByUsername" :key="u.id">
                  <div class="user-details">
                    <div class="username">{{ u.username }}</div>
                    <div class="id">{{ u.id }}</div>
                  </div>
                  <div class="btn-unblock" @click="unBlockUser(u)">Unblock</div>
                </div>
              </div>
              <div v-else class="not-users-wrapper">
                Not blocked users at the moment
              </div>
            </div>
          </div>

          <div class="audio-messages-wrapper" v-if="selectedTab === 'audioMessages'">
            <div class="body" v-if="voicenotes.length">
              <div class="voicenote-wrapper" v-for="note in voicenotes" :key="note._id">
                <div class="user-wrapper">
                  <div class="id">Profile Id: {{ note.profileId }}</div>
                  <div class="details" @click="getMatchingUsers(note.profileId)">Details</div>
                </div>
                <div class="timestamp"> {{ getDateTime(note.createdAt) }} </div>
                <audio id="audio" :src="note.url" controls></audio>
              </div>
              <div class="load-more">
                <div class="plus" v-if="!noMorePage" @click="loadMoreVoicenotes">Load More</div>
                <div class="plus" v-else>No more voicenotes</div>
              </div>
            </div>

            <div class="body" v-else>
              <div class="not-voice-notes">
                Not voicenotes at the moment
              </div>
            </div>
          </div>

          <div class="contest-wrapper" v-if="selectedTab === 'contests'" >

            <div class="body" v-if="contests.length">
              <div v-for="contest of contests" :key="contest._id">
                <div @click="loadPlays(contest._id)" class="d-flex flex-column contest-item">
                  <div class="contest-title">{{ contest.title }}</div>
                  <div >{{ contest.end }}</div>
                </div>

              </div>
            </div>

            <div class="body" v-else>
              <div class="not-voice-notes">
                No Contests at the moment
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- Contest Details Modal-->
    <b-modal id="contestDetailsModal" ref="contestDetailsModal" size="xl" no-close-on-backdrop>
      <Plays :modal-data="modalData"></Plays>
    </b-modal>

    <!-- Block Users Modal-->
    <b-modal id="blockUserModal" ref="blockUserModal" hide-footer hide-header no-close-on-backdrop no-close-on-esc>
      <h4>Sure you want to block:</h4>
      <div class="modal-username">{{ userToblock.username }}</div>
      <div class="btn-wrapper">
        <div class="btn-modal btn-cancel" @click="cancelBlockUser">Cancel</div>
        <div class="btn-modal btn-confirm" @click="confirmBlockUser">Confirm</div>
      </div>
    </b-modal>

    <!-- Unblock Users Modal-->
    <b-modal id="userToUnblock" ref="userToUnblock" hide-footer hide-header no-close-on-backdrop no-close-on-esc>
      <h4>Sure you want to block:</h4>
      <div class="modal-username">{{ userToUnblock.username }}</div>
      <div class="btn-wrapper">
        <div class="btn-modal btn-cancel" @click="cancelUnblockUser">Cancel</div>
        <div class="btn-modal btn-confirm" @click="confirmUnblockUser">Confirm</div>
      </div>
    </b-modal>

    <!-- Find Users Modal-->
    <b-modal id="findUser" ref="findUser" hide-footer no-close-on-backdrop no-close-on-esc>
      <h4>Results for: <span>{{ query }}</span></h4>
      <div class="user-found-wrapper" v-if="searchUsers.length">
        <div class="user-found" v-for="(u, index) in searchUsers" :key="u._id">
          <div class="username">{{ u.chat.username }}</div>
          <div class="id">{{ u._id }}</div>
          <div class="email">{{ u.email }}</div>
          <div class="btn-block" v-if="!( u.blocked.includes(resource.chatRoomId) || u.blocked.includes('all') ) && !u.confirmation" @click="blockSearchUser(u.chat.username, u._id, index)">Block</div>
          <div class="confirmation-wrapper" v-if="!u.blocked.includes(resource.chatRoomId) && u.confirmation">
            <div class="btn-block" @click="cancelBlockSearchUser(index)">Cancel</div>
            <div class="btn-block" @click="confirmBlockSearchUser(index)">Confirm</div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import Drawing from './Drawing'
import Plays from './Plays'
import PubNubVue from 'pubnub-vue'
export default {
  name: 'Chat',
  props: {
    resource: Object
  },
  components: {
    // Drawing,
    Plays
  },
  data () {
    return {
      profile: null,
      messages: this.$pnGetMessage(`messages.${this.resource.chatRoomId}`, this.messagesHandler),
      historyMessages: [],
      message: '',
      modalData: {
        plays: [],
        selected_prf_id: 0
      },
      blockedUsers: [],
      userToblock: {},
      userToUnblock: {},
      searchTerm: '',
      query: '',
      queryObject: {},
      searchUsers: [],
      selectedTab: null,
      voicenotes: [],
      contests: [],
      participants: [],
      currentPage: null,
      noMorePage: false,
      tabs: [{
        label: 'Block Users',
        type: 'blockUsers'
      },
      {
        label: 'Audio Messages',
        type: 'audioMessages'
      }],
      voiceNoteInterval: null
    }
  },
  computed: {
    filterByUsername () {
      return this.blockedUsers.filter(u => {
        return u.username.toLowerCase().includes(this.searchTerm)
      })
    },
    getVoiceNotesUrl () {
      if (this.resource.type === 'station') {
        return `/stations/${this.resource.resourceId}/voicenotes`
      }
      return `/livestreams/${this.resource.resourceId}/voicenotes`
    },
    getTabsAsUser(){
        return [{
        label: 'Block Users',
        type: 'blockUsers'
      },
      {
        label: 'Audio Messages',
        type: 'audioMessages'
      },
      {
        label: 'Contests',
        type: 'contests'
      }]
    }
  },
  beforeDestroy () {
    console.log('Unsubscribed', `messages.${this.resource.chatRoomId}`)
    this.$pnUnsubscribe({
      channels: [`messages.${this.resource.chatRoomId}`]
    })

    if (this.voiceNoteInterval) {
      clearInterval(this.voiceNoteInterval)
      this.voiceNoteInterval = null
    }
  },
  async mounted () {
    try {
      this.getHistory()
      this.getContests()
      this.getParticipants()
      this.selectedTab = this.tabs[0].type
      this.profile = await this.getProfile()
      const res = await this.getBlockedUsers()
      this.blockedUsers = res.items

      console.log('Subscribed', `messages.${this.resource.chatRoomId}`)
      this.$pnSubscribe({
        channels: [`messages.${this.resource.chatRoomId}`],
        withPresence: true
      })
    } catch (err) {
      console.log('error')
      console.log(err)
    }
  },
  methods: {
    async loadPlays (contestId) {
      try {
        const contestData = this.contests.find((c) => c._id === contestId)
        const res = await this.axios.get(`/contests/${contestId}/plays`)
        this.modalData.contest = contestData
        this.modalData.plays = res.data.data
        // filter for plays that are after today
        // const plays = res.data.data
        // this.modalData.plays = plays.filter((e) => {
        //   const n = Date.now()
        //   const rd = Date.parse(e.run_date)
        //   return n <= rd
        // })
        this.$bvModal.show('contestDetailsModal')
      } catch (error) {
        console.log('e', error)
      }
    },
    addParticipants () {
      const participant = {
        vcreative_prf_id: 'vcreative_2',
        user_id: '',
        username: 'User Name'
      }
      const res = this.axios.post('/participants', participant)
    },
    async getContests () {
      const res = await this.axios.get(`/stations/${this.resource.resourceId}/contests`)
      // this.currentPage = res.data.response.pagination.current_page
      const contestsList = res.data.data
      const sortedContests = contestsList.reverse()
      sortedContests.splice(3)
      this.contests = sortedContests
    },
    async getParticipants () {
      const res = await this.axios.get('/participants')
      // this.currentPage = res.data.response.pagination.current_page
      this.participants = res.data.data
    },
    async getHistory () {
      const pubnub = PubNubVue.getInstance()
      const response = await pubnub.history(
        {
          channel: `messages.${this.resource.chatRoomId}`,
          count: 100, // how many items to fetch
          stringifiedTimeToken: true // false is the default
        }
      )
      this.historyMessages = this.formatHistoryMessages(response.messages)
      if (this.historyMessages.length > 0) {
        this.historyMessages.push({
          message: {
            id: 'hr',
            text: '',
            username: ''
          },
          timetoken: 'msg-row-divider'
        })
        this.scrollMessages('msg-row-divider')
      }
    },
    formatHistoryMessages (msgArray) {
      const historyArray = []
      for (const eachEntry of msgArray) {
        const history = {
          message: eachEntry.entry,
          timetoken: eachEntry.timetoken
        }
        historyArray.push(history)
      }
      return historyArray
    },
    getDateTime (isoDate) {
      const date = new Date(isoDate)
      return `${date.toDateString()}, ${date.toLocaleTimeString('en-us', { timeStyle: 'short' })}`
    },
    async loadVoiceNotes () {
      const res = await this.axios.get(this.getVoiceNotesUrl)
      this.currentPage = res.data.response.pagination.current_page
      this.voicenotes = res.data.response.data
    },
    async loadMoreVoicenotes () {
      const res = await this.axios.get(`${this.getVoiceNotesUrl}?page=${this.currentPage + 1}`)
      if ((this.currentPage + 1) === res.data.response.pagination.current_page) {
        this.noMorePage = true
      }
      this.currentPage = res.data.response.pagination.current_page
      this.voicenotes = this.voicenotes.concat(res.data.response.data)
    },
    async selectTab (type) {
      if (type === 'blockUsers') {
        this.currentPage = null
        this.noMorePage = false
        this.voicenotes = []
        this.selectedTab = type
        clearInterval(this.voiceNoteInterval)
        this.voiceNoteInterval = null
        return
      }

      await this.loadVoiceNotes()

      if (!this.voiceNoteInterval) {
        this.voiceNoteInterval = setInterval(async () => {
          await this.loadVoiceNotes()
        }, 60000)
      }

      this.selectedTab = type
    },
    getFormattedDate (timetokenString) {
      let timetokenInt = parseInt(timetokenString)
      timetokenInt = parseInt(timetokenInt / 10000, 10)
      const date = new Date(timetokenInt)
      return date.toLocaleTimeString()
    },
    scrollMessages (lastestMessageId) {
      this.$nextTick(() => {
        const element = document.getElementById(`${lastestMessageId}`)
        if (element) {
          document.getElementById('messages').scrollTop = element.offsetTop
        }
      })
    },
    getProfile () {
      const url = `/users/admin?admin=${this.resource.chat_admin_id}`
      return this.axios.get(url).then((response) => {
        return response.data
      })
    },
    getBlockedUsers () {
      const url = `/users/blocked?room=${this.resource.chatRoomId}`

      return this.axios.get(url).then((response) => {
        return response.data
      })
    },
    messagesHandler (data) {
      this.scrollMessages(data.timetoken)
    },
    async sendMessage () {
      const data = {
        text: this.message,
        username: this.profile.chat.username,
        id: this.profile._id
      }

      await this.$pnPublish({
        channel: `messages.${this.resource.chatRoomId}`,
        message: data
      })

      this.message = ''
    },
    blockSearchUser (username, id, index) {
      if (id === this.profile._id) return

      this.userToblock = {
        username,
        id,
        chatRoomId: this.resource.chatRoomId
      }

      this.searchUsers[index].confirmation = true
    },
    async confirmBlockSearchUser (index) {
      try {
        await this.axios.post('/users/blocked', { user: this.userToblock })
      } catch (err) {
        console.log(err)
      }

      await this.$pnPublish({
        channel: `user.blockGlobal.${this.userToblock.id}`,
        message: { type: 'blocked', room: `${this.resource.chatRoomId}` }
      },
      (status, response) => console.log(status, response))

      const blockedUser = {
        username: this.userToblock.username,
        id: this.userToblock.id
      }

      this.blockedUsers.push(blockedUser)
      this.userToblock = {}

      this.searchUsers[index].confirmation = false
      this.searchUsers[index].blocked.push(this.resource.chatRoomId)
    },
    cancelBlockSearchUser (index) {
      this.searchUsers[index].confirmation = false
      this.userToblock = {}
    },
    blockUser (username, id) {
      if (id === this.profile._id) return

      if (this.blockedUsers.length) {
        const isBlocked = this.blockedUsers.find(u => u.id === id)
        if (isBlocked) return
      }

      this.userToblock = {
        username,
        id,
        chatRoomId: this.resource.chatRoomId
      }

      this.$refs.blockUserModal.show()
    },
    async confirmBlockUser () {
      const blockedUser = {
        username: this.userToblock.username,
        id: this.userToblock.id
      }

      try {
        var res = await this.axios.post('/users/blocked', { user: this.userToblock })
        blockedUser._id = res.data.itemId
      } catch (err) {
        console.log(err)
      }

      await this.$pnPublish({
        channel: `user.blockGlobal.${this.userToblock.id}`,
        message: { type: 'blocked', room: `${this.resource.chatRoomId}` }
      },
      (status, response) => console.log(status, response))

      this.blockedUsers.push(blockedUser)
      this.userToblock = {}
      this.$refs.blockUserModal.hide()
    },
    async cancelBlockUser () {
      this.userToblock = {}
      this.$refs.blockUserModal.hide()
    },
    unBlockUser (user) {
      this.userToUnblock = user
      this.$refs.userToUnblock.show()
    },
    async confirmUnblockUser () {
      try {
        await this.axios.delete(`/users/blocked/${this.userToUnblock._id}`)
        const res = await this.getBlockedUsers()
        this.blockedUsers = res.items
      } catch (err) {
        console.log(err)
      }

      await this.$pnPublish({
        channel: `user.blockGlobal.${this.userToUnblock.id}`,
        message: { type: 'unblocked', room: `${this.resource.chatRoomId}` }
      })

      for (let i = 0; i < this.blockedUsers.length; i++) {
        if (this.blockedUsers[i].id === this.userToUnblock.id) {
          this.blockedUsers.splice(i, 1)
          break
        }
      }

      this.userToUnblock = {}
      this.$refs.userToUnblock.hide()
    },
    async cancelUnblockUser () {
      this.userToUnblock = {}
      this.$refs.userToUnblock.hide()
    },
    async getMatchingUsers (query) {
      if (query === '') { return }
      this.$store.commit('isLoading', true)
      this.queryObject.q = query
      const usersResponse = await this.getUsers(this.queryObject)
      this.searchUsers = usersResponse.data

      this.searchUsers.forEach((item) => {
        this.$set(item, 'confirmation', false)
      })

      this.$refs.findUser.show()
      this.$store.commit('isLoading', false)
    },
    getUsers (queryStringParams) {
      let url = '/users'
      if (queryStringParams) {
        url += '?'
        let hasGoneOnce = false
        for (const key in queryStringParams) {
          if (!hasGoneOnce) {
            hasGoneOnce = true
          } else {
            url += '&'
          }
          url += key + '=' + encodeURIComponent(queryStringParams[key])
        }
      }
      return this.axios.get(url)
    }
  }
}
</script>

<style>
  #blockUserModal .modal-body,
  #userToUnblock .modal-body,
  #findUser .modal-body {
    padding: 50px;
  }

  #findUser .modal-body {
    padding-top: 30px;
  }
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .tabs-btn-wrapper {
    margin-bottom: 10px;
  }

  .tabs-btn-wrapper .btn {
    margin-right: 10px;
  }

  .tabs-btn-wrapper .btn:hover {
    cursor: pointer;
    background-color: #efefef;
  }

  .tabs-btn-wrapper .btn.active {
    background-color: #182028;
    color: #ffffff;
  }

  .audio-messages-wrapper {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%);
    padding: 30px;
  }

  .audio-messages-wrapper .body {
    max-height: calc(100vh - 325px);
    overflow: scroll;
  }

  .audio-messages-wrapper .body .voicenote-wrapper {
    margin-bottom: 10px;
    background-color: #f1f3f4;
    border-radius: 5px;
    padding: 10px;
  }
.audio-messages-wrapper .body .voicenote-wrapper .timestamp
{
  padding-left: 18px;
  font-size: 11px;
  color: #726e6e;
}

  .audio-messages-wrapper .body .voicenote-wrapper .user-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
  }

  .audio-messages-wrapper .body .voicenote-wrapper .user-wrapper .details {
    font-size: 12px;
    background-color: #182028;
    color: #fff;
    padding: 1px 12px;
    border-radius: 12px;
    cursor: pointer;
  }

  .audio-messages-wrapper .body .voicenote-wrapper audio {
    width: 100%;
  }

  .audio-messages-wrapper .body .load-more {
    color: #fff;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 0;
    cursor: pointer;
  }

  .audio-messages-wrapper .body .load-more .plus {
    padding: 15px 0;
  }

  .contest-wrapper {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%);
    padding: 30px;
  }

  .contest-wrapper .body {
    max-height: calc(100vh - 325px);
  }

  .contest-wrapper .body .contest-item {
    margin-bottom: 10px;
    background-color: #f1f3f4;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
  }

  .contest-wrapper .body .contest-item .contest-title{
    font-weight: bold;
  }

  .time-font{
    font-size: 11px;
    color: grey;
  }
  .confirmation-wrapper {
    display: flex;
  }

  .confirmation-wrapper .btn-block:first-child {
    margin-right: 10px;
    background-color: grey;
  }

  .btn-block {
    margin-top: 5px;
    font-size: 12px;
    color: #fff;
    background-color: #182028;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
    transition: all .3s ease;
    max-width: 80px;
    display: flex;
    justify-content: center;
    padding: 4px 0;
  }

  .btn-block:hover {
    background-color: #2b3946;
  }

  .chat-page .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .chat-page .header .image-wrapper {
    display: flex;
    align-items: center;
  }

  .chat-page .header .user-search {
    min-width: 330px;
  }

  .chat-page .header .user-search .btn-info {
    background-color: #182028;
    border-color: #182028;
  }

  .chat-page .header .user-search .btn-info:hover {
    background-color: #2b3946;
  }

  .chat-page .header img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .chat-page .messages-wrapper .header {
    background-color: #182028;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }

  .messages-wrapper ul.messages {
    list-style: none;
    height: calc(100vh - 300px);
    overflow-y: scroll;
    margin: 0;
    padding: 30px 20px;
  }

  .messages-wrapper ul.messages li {
    clear: both;
    float: left;
    margin-bottom: 10px;
  }
  #msg-row-divider{
    float:none;
    text-align: center;
    font-size: 12px;
    color: grey;
  }

  .messages-wrapper ul.messages li .msg-wrapper {
    padding: 10px 15px;
    background-color: #fff;
    border-radius: 5px;
    font-size: 14px;
    // border: 1px solid #f0f0f1;
    cursor: pointer;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%);
  }

  .messages-wrapper ul.messages li .msg-wrapper span {
    display: block;
    font-size: 12px;
    color: grey;
  }

  #userToUnblock .modal-body h4,
  #blockUserModal .modal-body h4 {
    text-align: center;
    margin: 0;
  }

  #userToUnblock .modal-body .modal-username,
  #blockUserModal .modal-body .modal-username {
    text-align: center;
    margin: 25px 0 30px;
    font-size: 26px;
  }

  #userToUnblock .modal-body .btn-wrapper,
  #blockUserModal .modal-body .btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #userToUnblock .modal-body .btn-wrapper .btn-modal,
  #blockUserModal .modal-body .btn-wrapper .btn-modal {
    background-color: #182028;
    color: #fff;
    padding: 8px 35px;
    border-radius: 5px;
    cursor: pointer;
    transition: all .3s ease;
  }

  #userToUnblock .modal-body .btn-wrapper .btn-modal:hover,
  #blockUserModal .modal-body .btn-wrapper .btn-modal:hover {
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%);
  }

  #userToUnblock .modal-body .btn-wrapper .btn-modal.btn-cancel,
  #blockUserModal .modal-body .btn-wrapper .btn-modal.btn-cancel {
    margin-right: 5px;
  }

  .blocked-users-wrapper {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%);
  }

  .blocked-users-wrapper .header {
    background-color: #182028;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .blocked-users-wrapper .header .amount {
    margin-right: 10px;
  }

  .blocked-users-wrapper .search {
    padding: 30px 30px 20px;
  }

  .blocked-users-wrapper .body {
    max-height: calc(100vh - 325px);
    overflow: scroll;
  }

  .blocked-users-wrapper .body .user {
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .blocked-users-wrapper .body .user:last-child {
    border: none;
  }

  .blocked-users-wrapper .body .user .user-details {
    font-weight: 600;
    font-size: 14px;
  }

  .blocked-users-wrapper .body .user .user-details .id {
    color: grey;
    font-size: 12px;
    font-weight: normal;
  }

  .blocked-users-wrapper .body .user .btn-unblock {
    font-size: 12px;
    color: #fff;
    background-color: #182028;
    padding: 3px 10px;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
    transition: all .3s ease;
  }

  .blocked-users-wrapper .body .user .btn-unblock:hover {
    background-color: #2b3946;
  }

  .not-users-wrapper,
  .not-voice-notes {
    padding: 15px 30px 30px;
    font-weight: 600;
    text-align: center;
  }

  #findUser h4 {
    margin-bottom: 20px;
  }

  #findUser h4 span {
    color: grey;
  }

  #findUser .user-found-wrapper .user-found {
    padding: 15px 0;
    border-bottom: 1px solid #f1f1f1
  }

  #findUser .user-found-wrapper .user-found:last-child {
    border: none;
  }

  #findUser .user-found-wrapper .user-found .username,
  #findUser .user-found-wrapper .user-found .email {
    font-weight: 700;
    font-size: 15px;
  }

  #findUser .user-found-wrapper .user-found .id {
    color: grey;
    font-size: 13px;
    font-weight: normal
  }
</style>
